.contactInfo {
  background-color: #ececec;
  .innerContainer {
    justify-content: center;
    align-items: center;
    .mainContent {
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 900px;
      .contactCards {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;

        .contactCard {
          margin: 20px;
          flex: 1;
          padding: 30px;
          border: 1px solid #b3b3b3;
          border-radius: 5px;
          background-color: white;
          max-width: 500px;
        }
      }
    }
  }
}

.doubleBox {
  padding: 0;

  @media (max-width: 900px) {
    &:nth-child(2n) {
      .sectionParent {
        flex-direction: column-reverse !important;
      }
    }
  }

  .innerContainer {
    padding: 0;
  }

  .sectionParent {
    display: flex;
    flex-direction: row;

    @media (max-width: 900px) {
      flex-direction: column;
    }

    .sectionKubbur {
      flex: 1;
      align-items: center;
      justify-content: center;
      display: flex;
      position: relative;
      background-color: #333132;

      .textKubbur {
        max-width: 550px;
        padding: 20px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .ant-typography {
          color: #fff !important;
        }
      }

      img {
        width: 100%;
      }

      &.overlay {
        &::after {
          /* background: linear-gradient(
            180deg,
            rgba(51, 49, 50, 0.86) 0%,
            rgba(255, 255, 255, 0) 100%
          ); */
          position: absolute;
          width: 100%;
          height: 100%;
          content: "";
        }
      }
    }
  }
}
