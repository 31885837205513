@import "./variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700;900&family=Roboto:wght@300&family=Vollkorn:ital,wght@1,500&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

img {
  max-width: 100%;
}

.ant-typography ul {
  list-style-type: inherit !important;
}

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mt40 {
  margin-top: 40px;
}

.mt50 {
  margin-top: 50px;
}

.topLevelContainer {
  padding-top: 135px;
}

.teymid {
  background-color: #f4f4f4;
}

.not-found {
  background-color: #000;
  color: #fff;
  height: calc(100vh - 135px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 58px;
    color: #fff;
    margin: 0;
  }

  h2 {
    color: #fff;
  }
}

.frontpageTitle {
  font-size: 48px;
  font-weight: 700;
  font-family: poppins, sans-serif;
  color: #fff;
}

#kiwiMenu {
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 9998;
  color: #fff;
  display: flex;
  justify-content: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  left: 0;

  transition: all 0.3s ease-in-out;

  &.hidden {
    left: -110%;
  }

  .menuItems {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 500px;
    gap: 20px;

    a,
    a:visited,
    a:hover,
    a:active {
      color: #fff;
    }

    @media (max-width: 700px) {
      width: 100%;
      padding: 30px;
    }
  }
}

.sectionTwo {
  .mainContent {
    max-width: 640px;
    margin: auto;
    display: flex;

    .sectionTwoTitle {
      h1 {
        font-family: Poppins;
        font-size: 96px;
        font-style: normal;
        font-weight: 800;
        line-height: 87px;
        letter-spacing: 0em;
        text-align: left;
        margin: 0;
      }
    }

    .sectionTwoBody {
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: left;
      margin-top: 120px;
      margin-left: 20px;
      margin-bottom: 0;

      a,
      a:hover,
      a:active,
      a:focus {
        color: #fff;
        font-weight: bold;
      }
    }
  }
}

.whiteFont {
  color: #fff !important;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #fff !important;
  }

  .ant-typography {
    color: #fff !important;
  }
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.header {
  top: 0;
  position: fixed;
  display: flex;
  width: 100%;
  height: 100px;
  z-index: 1;
  background: transparent;
  z-index: 9999;
  padding: 30px;
  justify-content: space-between;

  @media (max-width: 500px) {
    padding: 0 10px;
  }

  &.light {
    .hamburgerIcon {
      color: #000;
    }
  }

  .logo {
    img {
      display: block;
      width: 70px;
      margin-left: 0;
    }
  }

  .hamburgerIcon {
    color: #fff;
    font-size: 25px;
    line-height: 47px;
    cursor: pointer;

    &.light {
      color: #000;
    }
  }
}

.frontpage_map {
  .ant-card-body {
    padding: 0;
  }
}

.map-responsive {
  overflow: hidden;

  padding-bottom: 56.25%;

  position: relative;

  height: 0;
}

.map-responsive iframe {
  left: 0;

  top: 0;

  height: 100%;

  width: 100%;

  position: absolute;
}

.contentCard {
  .ant-card-head {
    border-bottom: 0;
  }

  .ant-card-head-title {
    padding-bottom: 0;
    padding-top: 30px;
  }

  ul {
    margin-left: 30px;
  }

  li {
    list-style-type: initial;
  }

  max-width: 1100px;
  margin: auto;
  border: none;
  text-align: justify;
  border-radius: 0;
}

.main_cards_frontpage {
  display: flex;
  justify-content: center;
  color: #fff;

  .ant-card {
    max-width: 500px;
    margin: 10px;
    width: 100%;
    box-shadow: 0 20px 60px rgba(0, 0, 0, 0.08);
    background-color: rgba(255, 255, 255, 0.8);

    .ant-card-head {
      background: inherit;
      color: inherit;
    }
  }
}

.ant-picker-calendar-mode-switch {
  display: none;
}

.mainTitle {
  p {
    color: #fff;
  }
}

.richTextContent {
  line-height: 28px;

  img {
    display: block;
    margin-bottom: 10px;
  }
}

.richTextImage {
  margin: auto;
}

@media (max-width: 900px) {
  .main_cards_frontpage {
    flex-direction: column;
    align-items: center;
  }
}

.newsItem {
  &:not(:last-child) {
    border-bottom: 1px solid rgba(185, 185, 185, 0.35);
  }
}

.stayInTouchFooter {
  background: linear-gradient(180deg, #3bd3c3 0%, #0fc5ff 100%),
    linear-gradient(125.79deg, #6bd3c4 16.39%, #466ba4 81.26%);
  text-align: center;
  padding: 50px;

  a {
    color: #fff;
  }
}

.kiwiFooter {
  span {
    margin: 15px;
  }
}

.kubbur {
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  min-height: 80vh;
  padding: 100px;
  display: flex;

  &.imageOnTheRight {
    .kubburInner {
      flex-direction: row-reverse;
    }
  }
  .kubburInner {
    display: flex;
    flex-direction: row;
    max-width: 1400px;
    margin: auto;
  }

  .side {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
  }

  &.dark {
    background-color: #000;
    color: #fff;

    .content {
      color: #fff;
    }
  }

  &.light {
    background-color: #fff;
    color: #000;
  }

  .title {
    font-family: Poppins;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 51px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 50px;
  }

  .content {
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;

    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
}
